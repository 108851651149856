import React, { useState, useEffect } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { AiOutlineBell, AiOutlineHome } from "react-icons/ai";
import { MdDeveloperBoard } from "react-icons/md";
import "./Sidebar.css";
import { useHistory, useLocation } from "react-router";
import brandLogo from "../../assets/images/seafood7.svg";
import sfnLogo from "../../assets/images/sfn-logo.png";
import AuthServices from "../../api/AuthServices";
import { ToastsStore } from "react-toasts";
import { confirmAlert } from "react-confirm-alert";
import { GiTrojanHorse } from "react-icons/gi";
import ReactTooltip from "react-tooltip";
import BrowserStorage from "../../utils/BrowserStorage";
import CommonServices from "../../api/CommonServices";
import roleManagement from "../../settings/roleManagement";
import { FaRegHandshake } from "react-icons/fa";

const Sidebar = () => {
  const history = useHistory();
  const [redirect, setRedirect] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [options, setOptions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [prospects, setProspects] = useState([]);
  const [type, setType] = useState("");
  const userInfo = BrowserStorage.getValue("userInfo");

  const getAllRoutes = (roleManagement = []) => {
    let routes = [];
    roleManagement.forEach((d) => {
      if (
        (!d.roles ||
          d.roles.length <= 0 ||
          (d.roles.length && d.roles.includes(userInfo.currentRole))) &&
        d.label &&
        d.label !== ""
      ) {
        let nestedRoutes = getAllRoutes(d.children || []) || [];
        let newData = {
          label: d.label,
          link:
            nestedRoutes.find((x) => x.link === d.path)?.link ||
            nestedRoutes?.[0]?.link ||
            d.path ||
            "",
          image: d.image?? <></>,
          subMenu: nestedRoutes,
        };
        if (
          !d.children?.length ||
          (d.children?.length && nestedRoutes.length > 0)
        )
          routes.push(newData);
      }
    });
    return routes;
  };
  let purchaserLabels = getAllRoutes(roleManagement || []) || [];

  const menuLabels = [
    {
      icon: <GiTrojanHorse color="2980b9" size={20} />,
      link: "https://demo.exocoetidae.no/admin",
      tooltip: "XO demo",
    },
    {
      icon: <GiTrojanHorse color="e74c3c" size={20} />,
      link: "https://app-demo.seafood7.no",
      tooltip: "Up demo",
    },
    {
      icon: <GiTrojanHorse color="002776" size={20} />,
      link: "https://mid-demo.seafood7.no",
      tooltip: "Mid demo",
    },
  ];

  const boardLabels = [
    {
      icon: <MdDeveloperBoard color="2980b9" size={20} />,
      link: "https://dev.exocoetidae.no/",
      tooltip: "Dev",
    },
    {
      icon: <MdDeveloperBoard color="e74c3c" size={20} />,
      link: "https://mid-dev.seafood7.no/",
      tooltip: "Mid Dev",
    },
    {
      icon: <MdDeveloperBoard color="002776" size={20} />,
      link: "https://app-dev.seafood7.no/",
      tooltip: "App Dev",
    },
  ];

  // let isUrlHavingBuy = window.location.href.indexOf('Buy') > -1;
  // let isUrlHavingSell = window.location.href.indexOf('Sell') > -1;
  const pathname = window.location.pathname;
  // const splitedPathname = pathname.split('/')[1];
  useEffect(() => {
    if (["customer_admin"].includes(userInfo.currentRole)) {
      getUserList();
      getProspects();
    }
    getShipmentLocation();
  }, []);

  const getShipmentLocation = async () => {
    const ShipmentLocation = await CommonServices.getShipmentLocation();

    if (ShipmentLocation.data) {
      const { data } = ShipmentLocation;
      setLocations(data.results);
    }
    if (ShipmentLocation.error) {
      console.log("ERROR Get Shipment Location", ShipmentLocation.error);
    }
  };

  const getProspects = async () => {
    try {
      const ProspectLists = await CommonServices.Prospects();

      if (ProspectLists.data) {
        const { data } = ProspectLists;
        setProspects(data.results);
      }
    } catch (error) {
      console.log("ERROR Getting Prospects", error);
    }
  };

  const getUserList = () => {
    // setOptions(JSON.parse(localStorage.customerList||''));
  };

  const handleChange = (e) => {
    localStorage.setItem("customerLocation", e.target.value);
    localStorage.removeItem("prospect");
    window.location = "/home/";
  };

  const handlePropspectChange = (e) => {
    localStorage.setItem("prospect", e.target.value);
    localStorage.removeItem("customerLocation");
    window.location = "/home/";
  };

  const handleLogout = async () => {
    const logout = await AuthServices.logout();
    if (logout.data) {
      console.log(logout, "Token removed from sever");
    } else {
      console.error(logout.error);
    }
    localStorage.clear();
    history.push("/login");
    // setRedirect(true);
  };

  const handleBell = () => {
    confirmAlert({
      message:
        "Here comes a notification functionality on time critical events in the database",
    });
  };
  return (
    <>
      <div className={"header"}>
        {userInfo.stream_type === "mid_stream" && (
          <>
            {((userInfo.userName || "").startsWith("Ronny") ||
              (userInfo.userName || "").startsWith("Lija")) && (
              <>
                {" "}
                <a href="https://www.seafood7.no/" target="__blank">
                  <div class="shaded_btn">Seafood7</div>
                </a>{" "}
                <a href="https://app.seafood7.no/" target="__blank">
                  <div class="shaded_btn">App</div>
                </a>{" "}
                <a href="https://exocoetidae.no/admin/" target="__blank">
                  <div class="shaded_btn">Exo</div>
                </a>{" "}
                <a href="https://bitfish.no/admin/" target="__blank">
                  <div class="shaded_btn">Bitfish</div>
                </a>{" "}
                <a href="https://sea7.no/admin/" target="__blank">
                  <div class="shaded_btn">Sea7</div>
                </a>{" "}
                <a href="https://mail.google.com/mail/" target="_blank">
                  <div class="shaded_btn">Post</div>
                </a>{" "}
                <a href="https://aralden.no/admin/" target="__blank">
                  <div class="shaded_btn">Aralden</div>
                </a>
                {/* <ul className="menu" style={{ marginLeft: 25 }}>
                  {boardLabels.map(function (name, index) {
                    return (
                      <li>
                        <a
                          href={name.link}
                          target="_blank"
                          data-tip={name.tooltip}
                        >
                          {name.icon}
                        </a>
                      </li>
                    );
                  })}
                </ul> */}
                <ul className={"menu"}>
                  {menuLabels.map(function (name, index) {
                    return (
                      <li>
                        <a
                          href={name.link}
                          target="_blank"
                          data-tip={name.tooltip}
                        >
                          {name.icon}
                        </a>
                        <ReactTooltip />
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </>
        )}
        <ul className="menu" style={{ marginRight: 20 }}>
          <li>
            <AiOutlineBell
              size={20}
              onClick={handleBell}
              style={{ cursor: "pointer" }}
            />
          </li>
        </ul>


        <div
          className={"pro_name"}
          onClick={() => setShowLogout((prev) => (prev = !prev))}
        >
          <div className={"pro_ico"}>
            {userInfo.userName ? userInfo.userName.charAt(0) : ""}
          </div>
          {userInfo.userName || ""}
          {/* {redirect && renderRedirect()} */}
          {showLogout && (
            <div className={"logout_pop"} onClick={handleLogout}>
              Logout
            </div>
          )}
        </div>
      </div>
      <div className={"side_bar_wrap"}>
        <div className="sidebar-logo-wrap">
          <img src={brandLogo} alt="brand-logo" width="110" height="44" />
        </div>
        <ul className={"sideMenu"}>
          {purchaserLabels.map((name, index) => {
            return(
            <>
              {name.subMenu.length ? (
                <div className="nav_item" key={index}>
                  <li className={`outer_con ${"active_route"}`} key={index}>
                    <NavLink to={name.link}>
                      <div className={`menu_item ${name.label}`}>
                        {name.image}
                        <div className={"nav_label"}>{name.label}</div>
                      </div>
                    </NavLink>
                    <div
                      className={
                        // splitedPathname == name.label
                        pathname.includes(name.label.toLowerCase())
                          ? "active_route"
                          : "inactive_route"
                      }
                    >
                      <div className={`subMenu_con`}>
                        {name.subMenu.map(function (item, i) {
                          return (
                            <NavLink
                              to={item.link}
                              activeClassName={"activeNav"}
                              key={i}
                            >
                              <li>
                                <div
                                  className={`menu_item ${
                                    item.label ? "sub_menu_item" : ""
                                  }`}
                                  style={{paddingLeft:70}}
                                >
                                  <div
                                    className={`${
                                      item.label ? "nav_ico" : "nav_icon"
                                    }`}
                                  ></div>

                                  <div className={"nav_label"}>
                                    {" "}
                                    {item.label}
                                  </div>
                                </div>
                              </li>
                            </NavLink>
                          );
                        })}
                      </div>
                    </div>
                  </li>
                </div>
              ) : (
                <NavLink
                  to={name.link}
                  activeClassName={"activeNav"}
                  key={index}
                >
                  <li key={index + 1}>
                    <div className={"menu_item"}>
                      {name.image}
                      <div className={"nav_label"}>{name.label}</div>
                    </div>
                  </li>
                </NavLink>
              )}
            </>
          )})}
        </ul>
        <div className={"sfn-logo"}>
          <img src={sfnLogo} alt="sfn-logo" width="60" height="60" />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
