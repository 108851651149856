import React, { useEffect, useState } from 'react';
import CalendarServices from '../../../api/CalendarServices';
import WeekSelector from './WeekSelector';

const WeekSelectorWrapper = ({
	access_type,
	access_id,
	modelName,
	selectedSourcingWeek,
	onWeekClick,
	is_disabled = false,
	updateActiveWeekDataStatus = () => {}
}) => {
	const [last_selector_week, setLastSelectorWeek] = useState('');
	const [first_allowed_week, setFirstAlowedWeek] = useState('');
	const [last_allowed_week, setLastAllowedWeek] = useState('');
	const week_data_stats = [true, true, true, true, true, true];

	useEffect(() => {
		getWeekDates();
	}, []);

	const getWeekDates = async () => {
		const weekDetails = await CalendarServices.getWeekDates();
		if (weekDetails.data) {
			const { data } = weekDetails;
			setLastSelectorWeek(data.sourcing_week);
			setFirstAlowedWeek(data.first_allowed_week);
			setLastAllowedWeek(data.sourcing_week);
			onWeekClick(data.sourcing_week);
		}
	};

	const onBFsClick = (last_selector_week) => {
		onWeekClick('');
		setLastSelectorWeek(last_selector_week);
	};
	const onToggleChange = () => {
		setLastSelectorWeek(last_allowed_week);
		onWeekClick(last_allowed_week);
	};

	return (
		<div className={'nasdaq_week'} style={{ display: 'flex' }}>
			<div className={'nasdaq_week_wrapper'}>
				<WeekSelector
					selector_title={'Week'}
					last_selector_week={last_selector_week}
					first_allowed_week={first_allowed_week}
					last_allowed_week={last_allowed_week}
					selected_week={selectedSourcingWeek}
					default_selected_week={last_allowed_week}
					week_limit={6}
					week_data_stats={week_data_stats}
					style={'bubbles'}
					week_selector_toogle={true}
					onWeekClick={onWeekClick}
					onBFsClick={onBFsClick}
					onToggleChange={onToggleChange}
					is_disabled={is_disabled}
					access_type={access_type}
					access_id={access_id}
					modelName={modelName}
					updateActiveWeekDataStatus={updateActiveWeekDataStatus}
				/>
			</div>
		</div>
	);
};

export default WeekSelectorWrapper;
