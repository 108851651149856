import React, { useReducer, useEffect } from 'react';
import '../Nasdaq/nasdaq.css';
/*
The final state management of the button is done on the component level. All variables
are passed down without changes from the highest level. The individual button may have
the following characteristics:
1. Being the current_week (normally underlined).
2. Being the last_week_of_interest (distinct underline or some other feature)
3. Being in the future (normally disabled and lightgrey)
4. Being in the past (standard color)
5. Being the selected_week (normally dark blue)
6. Being the default_selected_week (same behavior as 5)—implies that the it has date and that the date are shown
7. Being a default_disabled week
8. Being a dataless week (normally light red color, and sometimes disabled)
*/

const reducer = (state, action) => {
	switch (action.type) {
		case 'initiateButton':
			return {
				...state,
				background_color: action.payload.background_color,
				color: action.payload.color,
				disabled: action.payload.disabled,
				cursor: action.payload.cursor,
			};
		case 'setSelected':
			return {
				...state,
				background_color: action.payload.background_color,
				color: action.payload.color,
			};
		default:
			throw new Error("Don't understand action.");
	}
};

const WeekButton = ({
	week,
	week_no,
	last_selector_week,
	status,
	selected_week,
	default_selected_week,
	data_stat,
	active_data_staus,
	default_disabled_weeks,
	style,
	onWeekClick,
	weeks,
	showInactiveBorder = false,
}) => {
	const [state, dispatch] = useReducer(reducer, {
		background_color: 'white', // default
		color: '#002776',
		disabled: false,
		cursor: 'pointer',
		current: false,
		last_week_of_interest: false,
	});

	useEffect(() => {
		let disabled = state.disabled;
		let background_color = state.background_color;
		let color = state.color;
		let cursor = state.cursor;
		if (
			(default_disabled_weeks && default_disabled_weeks.includes(week)) ||
			!weeks.includes(last_selector_week) ||
			!data_stat ||
			status === 'future'
		) {
			// alert(data_stat)
			disabled = !data_stat ? true : false;
			cursor = !data_stat ? 'not-allowed' : 'pointer';
			background_color =
				status === 'future' ||
				(default_disabled_weeks && default_disabled_weeks.includes(week))
					? 'lightgrey'
					: !data_stat
					? 'rgba(254,68,56,.47)'
					: 'white';
			color = !data_stat ? 'white' : '#002776';
		} else {
			disabled = false;
			cursor = 'pointer';
			background_color = 'white';
			color = '#002776';
		}
		dispatch({
			type: 'initiateButton',
			payload: {
				background_color: background_color,
				color: color,
				disabled: disabled,
				cursor: cursor,
			},
		});
	}, [data_stat, status, weeks]);

	useEffect(() => {
		dispatch({
			type: 'setSelected',
			payload: {
				color: selected_week === week || !data_stat ? 'white' : '#002776',
				background_color:
					selected_week === week && data_stat
						? '#002776'
						: data_stat
						? 'white'
						: status === 'future' ||
						  (default_disabled_weeks && default_disabled_weeks.includes(week))
						? 'lightgrey'
						: !data_stat
						? 'rgba(254,68,56,.47)'
						: 'white',
			},
		});
	}, [selected_week]);

	return (
		<>
			<button
				disabled={state.disabled}
				style={{
					backgroundColor: state.background_color,
					cursor: state.cursor,
					color: state.color,
					border: active_data_staus
						? '2px solid #002776'
						: showInactiveBorder
						? '0.2px solid rgba(0, 0, 0,0.1'
						: '1px solid  #002776',
				}}
				className={`week_selc_btn ${
					default_selected_week === week && 'current_week'
				}`}
				value={week}
				onClick={!state.disabled ? onWeekClick : null}
			>
				{style === 'bubbles' ? week_no : week}
			</button>
		</>
	);
};

export default WeekButton;
