import React, { useEffect, useReducer } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import OrderVolumeMatrixComponent from '../components/Matrix/OrderVolumeMatrix';
import matrixWithToggleSwitch from '../hoc/matrixWithToggleSwitch';
import RFQMatrixComponent from '../components/Matrix/RFQMatrix';
import CommonServices from '../api/CommonServices';
import GlobalMatrixCard from '../components/GlobalMatrixCard/GlobalMatrixCard';
import RFQRanking from '../components/RFQ/RFQRanking';

const OutlookMatrix = matrixWithToggleSwitch(OrderVolumeMatrixComponent, {
	title: 'Outlook Matrix',
});

const RFQMatrix = matrixWithToggleSwitch(RFQMatrixComponent, {
	title: 'RFQs',
});

const AccumulatorMatrix = matrixWithToggleSwitch(OrderVolumeMatrixComponent, {
	title: 'Accumulator Matrix',
});

const ResidualMatrix = matrixWithToggleSwitch(OrderVolumeMatrixComponent, {
	title: 'Residual Matrix',
});

export const ACTIONS = {
	GET_MATRIX: 'GetMatrix',
	SET_FIELDS: 'SetFields',
	ON_RESIDUAL: 'ONResidual',
	OFF_RESIDUAL: 'OFFResidual',
	SET_HEADER_INFO: 'SET_HEADER_INFO',
	SET_BOX_AND_VOLUME: 'SetBoxVolumes',
	SET_RFQ_RANKING_MODAL_OPEN: 'SET_RFQ_RANKING_MODAL_OPEN',
	SET_ACCUMULATED: 'SET_ACCUMULATED',
};

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTIONS.SET_RFQ_RANKING_MODAL_OPEN:
			return {
				...state,
				isRFQRankingModalOpened: payload,
			};
		case ACTIONS.GET_MATRIX:
			return {
				...state,
				initial_matrix_data: payload.initial_matrix_data,
				residual_matrix_data: payload.residual_matrix_data,
				table_header_weeks: payload.table_header_weeks,
				week_data: payload.week_data,
				sourcing_week: payload.sourcing_week,
				shipment_week: payload.shipment_week,
				shipment_date: payload.shipment_date,
				sourcing_date: payload.sourcing_date,
			};
		case ACTIONS.SET_FIELDS:
			return {
				...state,
				[payload.field]: payload.value,
			};
		case ACTIONS.ON_RESIDUAL:
			return {
				...state,
				disable_residual: false,
				toggle_residual: true,
				toggle_producer: true,
				toggle_outlook: true,
			};
		case ACTIONS.OFF_RESIDUAL:
			return {
				...state,
				disable_residual: true,
				toggle_residual: false,
				toggle_producer: false,
			};
		case ACTIONS.SET_BOX_AND_VOLUME:
			return {
				...state,
				total_matrix_volume: payload.total_matrix_volume,
				total_matrix_weight: payload.total_matrix_volume * 22,
			};
		case ACTIONS.SET_HEADER_INFO:
			return {
				...state,
				headerInfo: { ...state.headerInfo, ...payload },
			};
		case ACTIONS.SET_ACCUMULATED:
			return {
				...state,
				accumulated_matrix_data: payload.accumulated_matrix_data,
			};
		default:
			return {
				...state,
			};
	}
};
const initalState = {
	initial_matrix_data: {},
	accumulated_matrix_data: {},
	residual_matrix_data: {},
	week_data: [],
	table_header_weeks: [],
	selected_producers: [],
	shipment_week: '',
	sourcing_date: '',
	shipment_date: '',
	total_matrix_weight: '',
	total_matrix_volume: '',
	disable_residual: true,
	matrix_dimension_status: 3,
	toggle_outlook: false,
	toggle_rfq: true,
	toggle_producer: false,
	toggle_residual: false,
	isRFQRankingModalOpened: false,

	headerInfo: {
		caption: 'RFQ Manager',
		subCaption: 'Request For Quote',
	},
	enabledComponents: [
		'HeadCaption',
		'ButtonComponent',
		'MatrixTotals',
		'WeekComponets',
		// 'NineStarSelector',
		// 'SplitSelector'
	],
};
function RFQManagerPage(props) {
	const [state, dispatch] = useReducer(reducer, initalState);

	useEffect(() => {
		fetchMatrix();
	}, []);

	// Fetch the initial Data to tender Matrix.
	const fetchMatrix = async () => {
		const matrixData = await CommonServices.getMatrixDataByComparison({
			model: 'RFQ',
			model_ids: [],
			comparison: 'outlook',
			comparison_ids: null,
		});
		if (matrixData.data) {
			const { data } = matrixData;
			dispatch({
				type: ACTIONS.GET_MATRIX,
				payload: {
					initial_matrix_data: data.outlook_matrix,
					residual_matrix_data: data.residual_matrix,
					week_data: data.matrix_date_list,
					table_header_weeks: [...data.matrix_date_list],
					sourcing_week: data.sourcing_week,
					shipment_week: data.shipment_week,
					shipment_date: data.shipment_date,
					sourcing_date: data.sourcing_date,
				},
			});
		}
	};

	//handle Producers/Exporters on Toggle ON/OFF
	const handleRFQProducer = async (producer, toggleStatus) => {
		let filteredProducers = [...state.selected_producers];
		let dispatchCondition = (Newproducer) =>
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'selected_producers',
					value: Newproducer,
				},
			});
		if (toggleStatus) {
			filteredProducers.push(producer.rfq_id);
			dispatchCondition(filteredProducers);
		} else {
			console.log('items', filteredProducers);
			filteredProducers = filteredProducers.filter(
				(item) => item !== producer.rfq_id
			);
			dispatchCondition(filteredProducers);
		}

		if (filteredProducers.length !== 0)
			try {
				const matrixData = await CommonServices.getMatrixDataByComparison({
					model: 'RFQ',
					model_ids: filteredProducers,
					comparison: 'outlook',
					comparison_ids: null,
				});
				if (matrixData.data) {
					const { data } = matrixData;
					let weightClasses = Object.keys(data.accumulated_matrix);
					console.log('Response Comparison Data', data);
					dispatch({
						type: ACTIONS.SET_FIELDS,
						payload: {
							field: 'residual_matrix_data',
							value: data.residual_matrix,
						},
					});
					dispatch({
						type: ACTIONS.SET_ACCUMULATED,
						payload: {
							accumulated_matrix_data: data.accumulated_matrix,
						},
					});
					let accumulated_data_matrix = data.accumulated_matrix;
					console.log('Weight Classes', weightClasses);
					console.log('Accumulated Data Matrix', accumulated_data_matrix);
					let total_volumes = 0;
					weightClasses.map((weight, index) => {
						accumulated_data_matrix[weight].map((cellData) => {
							total_volumes += parseInt(cellData.volume);
						});
					});
					dispatch({
						type: ACTIONS.SET_BOX_AND_VOLUME,
						payload: {
							total_matrix_volume: total_volumes,
						},
					});
					dispatch({
						type: ACTIONS.ON_RESIDUAL,
					});
				}
			} catch (err) {
				console.log(err);
			}
		else {
			dispatch({
				type: ACTIONS.SET_BOX_AND_VOLUME,
				payload: {
					total_matrix_volume: 0,
				},
			});
			dispatch({
				type: ACTIONS.OFF_RESIDUAL,
			});
		}
	};

	const handleToggleStatus = (toggleStatus, toggleType) => {
		console.log('handleToggleStatus');
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: toggleType,
				value: toggleStatus,
			},
		});
	};
	const manageRFQRankingModal = (modalOpen = false) => {
		dispatch({
			type: ACTIONS.SET_RFQ_RANKING_MODAL_OPEN,
			payload: modalOpen,
		});
	};

	return (
		<>
			<section className='sell_body_wrap'>
				{/* <Sidebar /> */}
				<div style={{ paddingBottom: 30 }} className='rfQ_fc_manager'>
					<div className='scrl_out' style={{ paddingBottom: '30px' }}>
						<Link to={'/home'}>
							<p className={'Back_link'}>Back</p>
						</Link>
						{/* <section className={'cmn_head'}>
							<div className={'cmn_head_align'}>
								<p>RFQ List</p>
							</div>
						</section> */}
						<RFQRanking
							isModalOpened={state.isRFQRankingModalOpened}
							manageRFQModal={manageRFQRankingModal}
						/>
						<GlobalMatrixCard
							//header information
							headerCaption={state.headerInfo.caption}
							headerSubCaption={state.headerInfo.subCaption}
							enabledComponents={state.enabledComponents}
							//matrix totals
							orderTotalBox={state.total_matrix_volume}
							dispalyOrderTotalBox={true}
							orderTotalWeight={state.total_matrix_weight}
							displayOrderTotalWeight={true}
							//Week component
							sourcingWeek={state.sourcing_week}
							sourcingDates={state.sourcing_date}
							shipmentWeek={state.shipment_week}
							shipmentDates={state.shipment_date}
							sourcingWeekDisplay={true}
							shipmentWeekDisplay={true}
							currentWeek={state.sourcing_week}
							// matrix Buttons
							rankingButtonDisplay={true}
							rankingButtonHandle={() => {
								manageRFQRankingModal(true);
							}}
						/>
						<OutlookMatrix
							orderVolumeMatrix={state.initial_matrix_data}
							type='view'
							state={state}
							daysOrder={state.table_header_weeks}
							toggleSwitchDisabled={false}
							toggleStatus={state.toggle_outlook}
							handleToggleStatus={(status) =>
								handleToggleStatus(status, 'toggle_outlook')
							}
						/>
						<RFQMatrix
							toggleSwitchDisabled={false}
							toggleStatus={false}
							handleRFQProducer={handleRFQProducer}
							toggleStatus={state.toggle_rfq}
							handleToggleStatus={(status) => {
								handleToggleStatus(status, 'toggle_rfq');
								dispatch({
									type: ACTIONS.OFF_RESIDUAL,
								});
							}}
						/>

						{
							<AccumulatorMatrix
								orderVolumeMatrix={state.accumulated_matrix_data}
								state={state}
								daysOrder={state.week_data}
								toggleSwitchDisabled={state.disable_residual}
								type='view'
								toggleStatus={state.toggle_producer}
								handleToggleStatus={(status) => {
									handleToggleStatus(status, 'toggle_producer');
								}}
							/>
						}

						<ResidualMatrix
							orderVolumeMatrix={state.residual_matrix_data}
							type='view'
							state={state}
							daysOrder={state.table_header_weeks}
							toggleSwitchDisabled={state.disable_residual}
							toggleStatus={state.toggle_residual}
							handleToggleStatus={(status) =>
								handleToggleStatus(status, 'toggle_residual')
							}
						/>
					</div>
				</div>
			</section>
		</>
	);
}

export default RFQManagerPage;
