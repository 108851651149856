import React, { useEffect, useState } from 'react';
import { ToastsStore } from 'react-toasts';

const WeightClassPrice = (props) => {
	let { onUpdate, enableUpdate, showPricesCol = true } = props;

	const [price, setPrice] = useState(props.price);
	let weight_class = price.weight_class;

	useEffect(() => {
		setPrice(props.price);
	}, [props.price]);

	const updatePrice = (e) => {
		if(parseInt(e.target.value)>=1000){
			ToastsStore.warning('Price cannot be more than 4 digits.')
			return;
		}

		const y = e.target.value.replace(/\s/g, '');
		const x = y.replace(/[^\w\s\.]/gi, '');
		var output = x.split('.');
		output = output.shift() + (output.length ? '.' + output.join('') : '');
		var t = output;
		output =
			t.indexOf('.') >= 0
				? t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 3)
				: t;


		price.price_nok = output;
		price.price_usd = '-';
		price.price_eur = '-';

		setPrice(price);

		let obj = { id: weight_class, price: output ? output : null };
		onUpdate(obj);
	};

	const onBlur = () => {
		price.price_nok = parseFloat(price.price_nok).toFixed(2);
		// price.price_usd = '-';
		// price.price_eur = '-';

		setPrice(price);

		let obj = { id: weight_class, price: price.price_nok };
		onUpdate(obj);
	};

	return (
		<div className='priceListBlock'>
			<div>
				{price.weight_class_prefix} {price.weight_class}{' '}
				{price.weight_class == 'NQSALMON' ? '' : 'kg'}
			</div>
			<div>
				{!enableUpdate && (
					<span>{price.price_nok ? '' + price.price_nok : '--'}</span>
				)}
				{enableUpdate && (
					<div className='ct_nok_price_holder'>
						<span>kr</span>
						<input
							type='number'
							step='0.01'
							className={'input price_input ct_price'}
							onBlur={onBlur}
							value={price.price_nok ? price.price_nok : ''}
							onChange={updatePrice}
						/>
					</div>
				)}
			</div>
			{showPricesCol && (
				<>
					<div className={'priceListusd'}>
						<span>
							{price.price_usd
								? '$' + price.price_usd
								: price && price.cost_target
								? '$' + price.cost_target.toFixed(2)
								: '--'}
						</span>{' '}
					</div>
					<div className={'priceListeur'}>
						<span>{price.price_eur ? '€' + price.price_eur : '--'}</span>
					</div>
				</>
			)}
		</div>
	);
};

export default WeightClassPrice;
