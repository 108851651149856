import React from "react";
import { toPascalCase } from "../../../util";
import ActiveMatrix from "../../../assets/images/ActiveMatrixIcon.png";
import InactiveMatrix from "../../../assets/images/InactiveMatrix.png";

function HeadCaption({
  caption,
  subCaption,
  MatixIconDisplay,
  showActiveMatrixIconDisplay,
}) {
  return (
    <div className="TopCaption">
      {caption && <p>{toPascalCase(caption)}</p>}
      {subCaption && <span>{subCaption}</span>}
      {MatixIconDisplay && (
        <div style={{ marginTop: 10 }}>
          <img
            src={showActiveMatrixIconDisplay ? ActiveMatrix : InactiveMatrix}
            style={{ height: 34, width: 34, opacity: 0.5 }}
          ></img>
        </div>
      )}
    </div>
  );
}

export default HeadCaption;
