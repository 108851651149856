import Api from './Api';

class CommonServices {
	getMatrixModels = async () => await Api.get(`business/api/matrix-model/`);

	getMatrixDataByComparison = async (data) =>
		await Api.post(`/business/api/matrix-model/matrix/`, data);

	getMatrixData = async (data) =>
		await Api.post(`/business/api/matrix-model/get-matrix/`, data);

	getQuotee = async (data) =>
		await Api.get('/accumulator/api/quotee/get-full?product_id=1&change_key=1')

	getAccessIdList = async (accessType) =>
		await Api.get(
			`/business/api/matrix-model/model-lookup/?model_name=${accessType}`
		);
	costTargetInitiate = async (access_type = 'purchaser') =>
		await Api.get(
			`/purchaser/api/week-sourcing-cost-target/initiate/?access_type=${access_type}`
		);

	getCostTargetPriceLine = async (sourcingWeek, accessType = 'purchaser') =>
		await Api.get(
			`/purchaser/api/week-sourcing-cost-target/?access_type=${accessType}&sourcing_week=${sourcingWeek}`
		);

	getCurrencyDetail = async (is_main = 1) =>
		await Api.get(`/administrator/api/currency/?is_main=${is_main}`);
	getCostTargetPriceHistory = async (sourcing_week, access_type = 'purchaser') =>
		await Api.get(
			`purchaser/api/week-sourcing-cost-target/history/?access_type=${access_type}&sourcing_week=${sourcing_week}`
		);

	updateCostTargetPriceLine = async (data, access_type = 'purchaser') =>
		await Api.post(
			`purchaser/api/week-sourcing-cost-target/?access_type=${access_type}`,
			data
		);

	addQuotePriceLine = async (data) =>
		await Api.post(`accumulator/api/quotee-price-line/`, data);
		
	addLightQuotePriceLine = async (data) =>
		await Api.post(`accumulator/api/light-quotee-price-line/`, data);

	////get outlook matrix
	getOutlookMatrix = async (data = {}) =>
		await Api.post(`/accumulator/api/outlook/outlook-matrix-accumulator/`, data);

	updateOutlook = async (data) =>
		await Api.post(`/accumulator/api/outlook/`, data);

	//CUSTOMER API's
	getCustomerUsers = async (params = {}) =>
		await Api.get('customer/api/customer-user/', { params });
	getShipmentLocation = async () =>
		await Api.get('/customer/api/customer-shipment-location/');
	setCustomerShipment = async (data) =>
		await Api.post('/customer/api/customer-shipment-location/', data);

	SelectedCustomerLocation = async () =>
		await Api.get('/customer/api/customer-shipment-location/list/?is_selected=1');
	UnSelectedCustomerLocation = async () =>
		await Api.get('/customer/api/customer-shipment-location/list/?is_selected=0');

	//PROSPECT API's
	Prospects = async () => await Api.get('/customer/api/prospect/');
	PostProspects = async (data) =>
		await Api.post('/customer/api/prospect/', data);
	SelectedProspects = async () =>
		await Api.get('/customer/api/prospect/?is_selected=1');
	UnselectedProspects = async () =>
		await Api.get('/customer/api/prospect/?is_selected=0');

	//PREPROSPECT API's
	PostPreProspect = async (data) =>
		await Api.post('/customer/api/pre-prospect/', data);
	SelectPreprospects = async () =>
		await Api.get('/customer/api/pre-prospect/?is_selected=1');
	UnSelectPreprospects = async () =>
		await Api.get('/customer/api/pre-prospect/?is_selected=0');

	//SEAFOOD DESTINATION
	PostDestination = async (data) =>
		await Api.post('/chain/api/seafood-destination/', data);
	SelectSeafoodDestination = async () =>
		await Api.get('/chain/api/seafood-destination/?is_selected=0');
	UnSelectSeafoodDestination = async () =>
		await Api.get('/chain/api/seafood-destination/?is_selected=1');

	//EMAIL API's
	PreviewMail = async () => await Api.get('accumulator/api/email-preview/');

	getNasdaqPriceHistory = async (limit, access_type = 'price') =>
		await Api.get(
			`price/api/nasdaq-index/history/?access_type=${access_type}&limit=${limit}`
		);

	getNQSPrices = async (params = {}) =>
		await Api.get(`/price/api/nasdaq-index/price-sets/`,{params});

	//PRODUCT API's
	getProductSpecification = async (params={}) => 
		await Api.get(`product/api/product-specification/`,{params});

	//GET SEAFOOD DESTINATIONS
	getSeafoodDestination = async () =>
		await Api.get('chain/api/seafood-destination/?request_type=all');
	getSeafoodDestinationByCountry = async (countryId) =>
		await Api.get(`chain/api/seafood-destination/?country=${countryId}`);
	
	//GET COUNTRIES
	getSeafoodCountries = async () => 
		await Api.get('market/api/seafood-country/')
	getAirport = async (country) =>
		await Api.get(`chain/api/airport/?country=${country}`)
	getTargetProduct = async() =>
		await Api.get('product/api/product/')
	getCurrency = async() =>
		await Api.get('administrator/api/currency/');
	postPreProspect = async(payload) =>
		await Api.post('/customer/api/pre-prospect/create/',payload)
	updateCustomerByPreprospect = async(id,payload) =>
		await Api.put(`/customer/api/pre-prospect/${id}/`,payload)
	updateCustomerByProspect = async(id,payload)=>
		await Api.put(`/customer/api/prospect/${id}/`,payload)
	updateCustomerByCSL = async(id,payload) =>
		await Api.put(`/customer/api/customer-shipment-location/${id}/update/`,payload)
	createPreUser = async(payload) =>
		await Api.post('customer/api/pre-user/',payload)
	getPreUser = async(id) =>
		await Api.get(`customer/api/pre-user/${id}/`)
	UpdatePreUser = async(id,payload) =>
		await Api.put(`customer/api/pre-user/${id}/`,payload)
	getCustomerUser = async(id) =>
		await Api.get(`customer/api/customer-user/details/?pk=${id}`)
	UpdateCustomerUser = async(id,payload) =>
		await Api.put(`customer/api/customer-user/${id}/`,payload)
		
	getCostTargetHistory = async (limit) =>
		await Api.get(`purchaser/api/week-sourcing-cost-target/week-history/?limit=${limit}`)
}

export default new CommonServices();
