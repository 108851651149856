import Api from './Api';

class TimelineServices {
	getShipments = async () =>
		await Api.get(`/transmitter/api/customer-shipment/current-shipments/`);

	getTimeline = async (id) =>
		await Api.get(`/transmitter/api/customer-shipment/${id}/shipment-timeline/`);
}
export default new TimelineServices();
