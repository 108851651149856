import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../Sidebar/Sidebar';
import Modal from '../../Ui/Modal/Modal';
import Moment from 'react-moment';
import './OrderSummary.css';
import '../RequestQuote/RequestQuote.css';
import { withRouter } from 'react-router-dom';
import QuoteServices from '../../../api/QuoteServices';
import { AiOutlineClockCircle } from 'react-icons/ai';

class OrderSummary extends Component {
	constructor() {
		super();
		this.state = {
			daysList: [],
			summaryData: null,
			quoteSummary: null,
			perKgPrice: null,
			showQuoteConfirmModal: false,
			onDiscussionPrices: [],
			shipmentMatrix: [],
		};
		this.weightClass = [];
		this.totalValue = [];
		// this.totalPrice = [];

		this.weekDet = [];
	}
	componentDidMount() {
		this.getDate();
		// this.getSummary()
		// this.getQuote();
		this.getOrder();
	}
	getDate() {
		var nextMonday = new Date();
		nextMonday.setDate(
			nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7)
		);
		var nextWeekArrays = [nextMonday];
		for (let i = 1; i < 7; i++) {
			nextWeekArrays.push(
				new Date(new Date(nextMonday).setDate(nextMonday.getDate() + i))
			);
		}
		this.weekDet = nextWeekArrays;
	}

	async getQuote() {
		const getQuote = await QuoteServices.getQuote();
		if (getQuote.data) {
			const { data } = getQuote;
			let weight = [];
			for (let item in data.active_admin_offer_limited) {
				weight.push(item);
			}
			let shipment = [];
			let columTotal = {};
			for (let item in data.limited_shipment_matrix) {
				shipment.push({
					date: item,
					list: data.limited_shipment_matrix[item],
				});
				data.limited_shipment_matrix[item].map((weight, index) => {
					columTotal[weight.weight_class] = columTotal[weight.weight_class]
						? columTotal[weight.weight_class] + weight.volume
						: weight.volume;
				});
			}
			console.log(weight);
			data.quote['quotee_total'] = data.quotee_total;
			data.quote['total_volume'] = data.total_volume;
			data.quote['total_weight'] = data.total_weight;
			this.setState({
				// daysList: Object.keys(data.quote_data),
				summaryData: data.quote_data,
				quoteSummary: data.quote,
				perKgPrice: data.active_admin_offer_full,
				// onDiscussionPrices: Object.keys(data.on_discussion_prices),
				// weightClass: weight,
				shipmentMatrix: shipment,
			});
			this.weightClass = weight;
			this.totalValue = columTotal;

			this.forceUpdate();
		}
	}

	async getOrder() {
		const getOrder = await QuoteServices.getOrder(localStorage.requestQuoteId, 1);
		if (getOrder.data) {
			const { data } = getOrder;
			let quote = {
				quote_week: data.quote_week,
				shipment_week: data.shipment_week,
				id: data.quotee_id,
				quotee_total: data.quotee_total,
				total_volume: data.total_volume,
				total_weight: data.total_weight,
				status: data.quote.status,
			};
			let weight = [];
			for (let item in data.matched_weight_class) {
				weight.push(data.matched_weight_class[item]);
			}
			let shipment = [];
			let columTotal = {};
			for (let item in data.check_out_shipment_matrix) {
				shipment.push({
					date: item,
					list: data.check_out_shipment_matrix[item],
				});
				data.check_out_shipment_matrix[item].map((weight, index) => {
					columTotal[weight.weight_class] = columTotal[weight.weight_class]
						? columTotal[weight.weight_class] + weight.volume
						: weight.volume;
				});
			}

			this.setState({
				quoteSummary: quote,
				perKgPrice: data.active_admin_offer_full,
				shipmentMatrix: shipment,
			});
			this.weightClass = weight;
			this.totalValue = columTotal;
			this.forceUpdate();
		}
	}

	async getSummary() {
		let data = {};
		const getOrderSummary = await QuoteServices.getOrderSummary(data);
		if (getOrderSummary.data) {
			const { data } = getOrderSummary;
			this.setState({
				daysList: Object.keys(data.data.quote_data),
				summaryData: data.data.quote_data,
				quoteSummary: data.data.quote,
				perKgPrice: data.data.prices,
				onDiscussionPrices: Object.keys(data.data.on_discussion_prices),
			});
			console.log(this.state.summaryData);
			let availableWeight = [];
			this.state.daysList.map((days, index) => {
				availableWeight.push(...Object.keys(this.state.summaryData[days]));
			});
			this.weightClass = Array.from(new Set(availableWeight));

			console.log('w_class', this.weightClass);
			let columTotal = [];
			let columnPriceTotal = [];
			this.state.daysList.map((days, index) => {
				this.weightClass.map((wClass, i) => {
					if (!columTotal.hasOwnProperty(wClass)) {
						columTotal[wClass] = this.state.summaryData[days][wClass]?.[0].volume
							? [this.state.summaryData[days][wClass]?.[0].volume]
							: [0];
						// columnPriceTotal[wClass] = this.state.summaryData[days][wClass]?.[0].price ? [this.state.summaryData[days][wClass]?.[0].price] : [0]
					} else {
						this.state.summaryData[days][wClass]?.[0].volume
							? columTotal[wClass].push(
									this.state.summaryData[days][wClass]?.[0].volume
							  )
							: columTotal[wClass].push(0);
						// this.state.summaryData[days][wClass]?.[0].price ? columnPriceTotal[wClass].push(this.state.summaryData[days][wClass]?.[0].price) : columnPriceTotal[wClass].push(0)
					}
					this.totalValue = columTotal;
					// this.totalPrice = columnPriceTotal;
				});
			});

			this.weightClass.map((weight, index) => {
				this.totalValue[weight] = this.totalValue[weight].reduce(function (a, b) {
					return a + b;
				}, 0);
				// this.totalPrice[weight] = this.totalPrice[weight].reduce(function (a, b) {
				//     return (a + b)
				// }, 0)
			});
			this.forceUpdate();
		}
	}
	async confirmOrder() {
		var data = {
			qoute_id: localStorage.requestQuoteId,
		};
		const confirmOrder = await QuoteServices.confirmOrder(
			data,
			localStorage.requestQuoteId
		);
		if (confirmOrder.data) {
			this.callOrderPlacedApi();
		}
	}

	callOrderPlacedApi = async () => {
		const convertQuoteToOrder = await QuoteServices.convertQuoteToOrder(
			localStorage.requestQuoteId,
			'web'
		);
		if (convertQuoteToOrder.data) {
			const { data } = convertQuoteToOrder;
			this.setState({
				showQuoteConfirmModal: !this.state.showQuoteConfirmModal,
			});
		}
	};

	confirmedQuote = () => {
		this.setState({
			showQuoteConfirmModal: !this.state.showQuoteConfirmModal,
		});
		window.location.href = '/sell/friday';
		// window.location.href = '/home';
	};

	render() {
		var daysOrder = ['', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
		return (
			<>
				{this.state.showQuoteConfirmModal && (
					<Modal>
						<div className={'pop_logo' + ' ' + 'hook'}></div>
						<p>
							Kindly note that we have received your Order.
							<br /> You will receive a notification once the Order is confirmed.
						</p>
						<div className={'modal_btn'} onClick={(event) => this.confirmedQuote()}>
							Okay
						</div>
					</Modal>
				)}

				<section className={'body_wrap'}>
					{/* <Sidebar /> */}
					<div className={'cont_wp'}>
						{/* {
                            this.state.quoteSummary ?.status !== 3 ? <Link to={'/sell/friday'}>
                                <p className={'Back_link'}>Back</p>
                            </Link> : <Link to={'/sell/friday'}>
                                    <p className={'Back_link'}>Back</p>
                                </Link>
                        } */}
						<p className={'Back_link'} onClick={this.props.history.goBack}>
							Back
						</p>
						<section className={'cmn_head'}>
							<p>Confirm Order</p>
						</section>

						<div className={'scrl_out'}>
							<div className={'Modify_top'}>
								<section>
									<div className={'mn_wr'}>
										<p className={'Cmn_in_head'}>
											Quote <span>( {this.state.quoteSummary?.id} )</span>
										</p>
									</div>

									<div className={'ReqBtnWrp'}>
										{this.state.quoteSummary?.total_volume > 0 ? (
											<div
												className={'conBt' + ' ' + 'sp_btn'}
												onClick={(event) =>
													this.state.quoteSummary?.status == 3
														? this.callOrderPlacedApi()
														: this.confirmOrder()
												}
											>
												Confirm Order
											</div>
										) : (
											<div
												className={'conBt' + ' ' + 'sp_btn'}
												onClick={this.props.history.goBack}
											>
												Back
											</div>
										)}
									</div>
								</section>
								<section>
									<div className={'Modify_lab_wrap'}>
										<div className={'mod_lab_out'}>
											<div className={'mod_lab'}>
												{this.state.quoteSummary?.total_volume > 0
													? this.state.quoteSummary?.total_volume
													: 0}{' '}
												{this.state.quoteSummary?.total_volume == 1 ? 'Box' : 'Boxes'}
											</div>
											<p>Order Total Box</p>
										</div>
										<div className={'mod_lab_out'}>
											<div className={'mod_lab'}>
												{this.state.quoteSummary?.total_volume > 0
													? this.state.quoteSummary?.total_weight
															.toFixed(2)
															.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
													: 0}{' '}
												kg
											</div>
											<p>Order Total Weight</p>
										</div>
										<div className={'mod_lab_out'}>
											<div className={'mod_lab'}>
												${' '}
												{this.state.quoteSummary?.quotee_total > 0
													? this.state.quoteSummary?.quotee_total
															.toFixed(2)
															.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
													: 0}
											</div>
											<p>Order Total USD</p>
										</div>

										<div className={'mod_lab_out'}>
											{
												<div className={'mod_lab'}>
													{this.state.quoteSummary?.quote_week}
												</div>
											}
											<p>Order Week</p>
										</div>
										<div className={'mod_lab_out'}>
											{
												<div
													className={'mod_lab'}
													style={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													{this.state.quoteSummary?.shipment_week}
													<AiOutlineClockCircle className={'clock_icon'} color='#002776' />
												</div>
											}
											<p>Shipment Week</p>
										</div>
										<div className={'mod_lab_out'}>
											{<div className={'mod_lab'}></div>}
											<p></p>
										</div>
									</div>
								</section>
							</div>

							<section className={'Summary_out'}>
								{this.state.onDiscussionPrices.length != 0 && (
									<p className={'note_p'}>
										<span>Note : </span> Ongoing price discussions are stopped and{' '}
										{this.state.onDiscussionPrices.length > 1
											? 'weight classes'
											: 'weight class'}
										{this.state.onDiscussionPrices.map((data) => {
											return <label> {data}kg, </label>;
										})}{' '}
										{this.state.onDiscussionPrices.length > 1 ? 'are' : 'is'} removed from
										the order.
									</p>
								)}
								<section className={'Summary_wrap'}>
									<div className={'Summary_row' + ' ' + 'header_row'}>
										<div className={'Summary_col' + ' ' + 'Summary_col_day'}></div>
										{this.weightClass.map((weight, i) => {
											return <div className={'Summary_col'}>{weight} kg</div>;
										})}
									</div>
									{this.state.shipmentMatrix.map((day, i) => {
										return (
											<div className={'Summary_row'}>
												<div className={'Summary_col' + ' ' + 'Summary_col_day'}>
													{daysOrder[day.date]}{' '}
													<span>
														<Moment format='MMM DD'>{this.weekDet[day.date - 1]}</Moment>
													</span>
												</div>
												{day.list.map((ship, i) => {
													return (
														<div className={'Summary_col'}>
															{ship.volume ? ship.volume : '-'}
														</div>
													);
												})}
											</div>
										);
									})}

									<div className={'Summary_row' + ' ' + 'footer_total'}>
										<div className={'Summary_col' + ' ' + 'Summary_col_day'}>
											Total Box
										</div>
										{this.weightClass.map((weight, i) => {
											return (
												<div className={'Summary_col'}>{this.totalValue[weight]}</div>
											);
										})}
									</div>

									<div className={'Summary_row' + ' ' + 'footer_total'}>
										<div className={'Summary_col' + ' ' + 'Summary_col_day'}>
											Price/kg
										</div>
										{this.weightClass.map((weight, i) => {
											return (
												<div className={'Summary_col'}>
													{this.state.perKgPrice[weight]?.toFixed(2)}
												</div>
											);
										})}
									</div>
								</section>
							</section>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default withRouter(OrderSummary);
