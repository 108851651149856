import React from 'react';
import { BiRefresh } from 'react-icons/bi';
import { FcNumericalSorting12 } from 'react-icons/fc';
import { GrDocument, GrDocumentText } from 'react-icons/gr';
import { FiThumbsDown } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';
import Button from '../../Elements/Button';
import CustomButton from '../../Elements/CustomButton';

/**
 * @author
 * @function ButtonComponent
 **/

const ButtonComponent = ({
	updateButtonDisplay,
	updateButtonDisable,
	updateButtonHandle,

	viewOrderButtonDisplay,
	viewOrderButtonDisable,
	viewOrderButtonHandle,

	viewPurchaseButtonDisplay,
	viewPurchaseButtonDisable,
	viewPurchaseButtonHandle,

	confirmPurchaseButtonDisplay,
	confirmPurchaseButtonDisable,
	confirmPurchaseButtonHandle,

	refreshButtonDisplay,
	refreshButtonHandle,

	rankingButtonDisplay,
	rankingButtonHandle,

	thumbsDownProducerButtonDisplay,
	thumbsDownProducerButtonHandle,
	thumbsDownProducerButtonColor,

	thumbsDownSf7ButtonHandle,
	thumbsDownSf7ButtonColor,
	thumbsDownSf7ButtonDisplay,

	emailButtonDisplay,
	emailButtonHandle,

	documentButtonDisplay,
	documentButtonHandle,
}) => {
	return (
		<>
			{updateButtonDisplay && (
				<Button
					className='fc_btn info'
					name='update_btn'
					disabled={updateButtonDisable}
					onClick={updateButtonHandle}
				>
					Update
				</Button>
			)}
			{viewOrderButtonDisplay &&(
				<Button
				className='fc_btn info'
				name='view_purchase_btn'
				disabled={viewOrderButtonDisable}
				onClick={viewOrderButtonHandle}
			>
				View Order
			</Button>
			)}

			{viewPurchaseButtonDisplay && (
				<Button
					className='fc_btn info'
					name='view_purchase_btn'
					disabled={viewPurchaseButtonDisable}
					onClick={viewPurchaseButtonHandle}
				>
					View Purchase
				</Button>
			)}
			{confirmPurchaseButtonDisplay && (
				<Button
					className='fc_btn info'
					name='view_purchase_btn'
					disabled={confirmPurchaseButtonDisable}
					onClick={confirmPurchaseButtonHandle}
				>
					Confirm Purchase
				</Button>
			)}

			{refreshButtonDisplay && (
				<CustomButton
					name='refresh_btn'
					className='refreshBtn'
					style={{ width: '45px', height: '45px' }}
					onClick={refreshButtonHandle}
				>
					<BiRefresh />
				</CustomButton>
			)}

			{rankingButtonDisplay && (
				<CustomButton
					name='ranking_btn'
					className='rankingBtn'
					onClick={rankingButtonHandle}
				>
					<FcNumericalSorting12 color='red' />
				</CustomButton>
			)}
			<div className='thumbs-button-group'>
				{thumbsDownProducerButtonDisplay && (
					<CustomButton
						name='producer_thumbs_down_btn'
						className='thumbsButton ' //producer-down-btn
						// style={{ width: '45px', height: '45px' }}
						onClick={thumbsDownProducerButtonHandle}
					>
						<div className='tooltip'>
							<span className='tooltiptext'>Producer</span>
							<FiThumbsDown color={thumbsDownProducerButtonColor || 'blue'} />
						</div>
					</CustomButton>
				)}
				{thumbsDownSf7ButtonDisplay && (
					<CustomButton
						name='sf7_thumbs_down_btn'
						className='thumbsButton ' //producer-down-btn
						// style={{ width: '45px', height: '45px' }}
						onClick={thumbsDownSf7ButtonHandle}
					>
						<div className='tooltip'>
							<span className='tooltiptext'>SF7</span>
							<FiThumbsDown color={thumbsDownSf7ButtonColor || 'red'} />
						</div>
					</CustomButton>
				)}
			</div>
			<div className='thumbs-button-group'>
				{emailButtonDisplay && (
					<CustomButton
						name='email'
						className='emailButton ' //producer-down-btn
						// style={{ width: '45px', height: '45px' }}
						onClick={emailButtonHandle}
					>
						<HiOutlineMail />
					</CustomButton>
				)}
				{documentButtonDisplay && (
					<CustomButton
						name='document'
						className='documentButton' //producer-down-btn
						// style={{ width: '45px', height: '45px' }}
						onClick={documentButtonHandle}
					>
						<GrDocumentText />
					</CustomButton>
				)}
			</div>
		</>
	);
};

export default ButtonComponent;
