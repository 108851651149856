import React from 'react';
import './SelectorCard.css';

const SelectorCard = ({
	title = '',
	state = [],
	setState,
	is_selected = true,
	disable = true
}) => {
	const renderSelector = (title, item) => {
		switch (title) {
			case 'Seafood Destination':
				return item.airport;
			case 'Pre Prospects':
				return item.prospect;
			case 'Prospects':
				return item.prospect;
			case 'Customers':
				return item.name;
			case 'Producers':
				return item.name;
			case 'Exporters':
				return item.name;
			default:
				return;
		}
	};

	console.log('item', state);

	return (
		<div className={'cards'}>
			<div className={'card_title_wrap'}>
				<p className={'card_title'}>{title}</p>
				<div
					className={`sell_btn ${disable ? 'not_allow_cursor' : 'allow_cursor'}`}
					onClick={() => disable ? null : setState('', is_selected ? 'removeAll' : 'addAll')}
				>
					{is_selected ? 'Remove All' : 'Add all'}
				</div>
			</div>
			{state.length > 0 ? (
				<div className={'card_body_wrap'}>
					{state.map((item) => {
						return (
							<div className={'card_item'} key={item.id}>
								<p>{renderSelector(title, item)}</p>
								<div
									className={is_selected ? 'move_icon' : 'move_icon up'}
									onClick={() => setState(item.id, is_selected ? 'unselect' : 'select')}
								>
									{is_selected ? 'v' : '^'}
								</div>
							</div>
						);
					})}
				</div>
			) : (
				<div className={'card_body_wrap'}>
					<div className={'card_no_items_wrap'}>
						<p className={'no_items_text'}>No items</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default SelectorCard;
