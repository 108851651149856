import React, { useEffect, useState } from "react";
import "../CostTarget/CostTarget.css";

const CurrencySelection = (props) => {
  let { currenciesList, onSelectChange, previousPriceList, selectedCurrency } = props;
  const [newCurrency, setNewCurrency] = useState(selectedCurrency);
  const [disabled, setDisable] = useState()

  useEffect(() => {
    let checkNotNull = !Object.values(previousPriceList).every((o) => o === null);
    
    setDisable(checkNotNull)
    setNewCurrency(selectedCurrency)

  }, [previousPriceList, selectedCurrency]);

  const updateCurrency = (e) => {
    setNewCurrency(e.target.value);
    onSelectChange(e.target.value);
  };
  return (
    <div className={"cost_target_header"}>
      <p>Currency: </p>

      <select value={newCurrency} onChange={(event) => updateCurrency(event)}disabled={disabled}>
        {currenciesList.map((data, i) => {
          return (
              <option key={i} disabled={disabled} value={data.currency_code}>{data.currency_code}</option>
          );
        })}
      </select>
    </div>
  );
};

export default CurrencySelection;
