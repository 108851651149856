import React, { useEffect, useState } from 'react';
import WeightClassPrice from './WeightClassPrice';
import './PriceLine.css';
import { GrHistory } from 'react-icons/gr';
import axios from 'axios';
import HistoryModalComponent from '../CostTarget/HistoryModalComponent';

const PriceLine = (props) => {
	let { priceList, onUpdate, enableUpdate, week, year, showPricesCol } = props;

	const [modal, setModal] = useState(false);
	const [history, setHistory] = useState(props.history);

	useEffect(() => {
		setHistory(props.history);
	}, [props.history]);

	const closeModal = () => {
		setModal(false);
	};

	return (
		<div className={'priceButtonWrap'}>
			<div className={'priceListBlock' + ' ' + 'boldHead'}>
				<div>Weight Class</div>
				<div className={'list_header_wrap'}>
					Price NOK
					{history ? (
						<GrHistory
							size={15}
							color={'#58637A'}
							onClick={() => {
								setModal(history.has_history);
							}}
							className={`${
								history.has_history ? 'allow_cursor' : 'not_allow_cursor'
							} history_icon`}
						/>
					) : null}
				</div>
				<div className={'list_price_usd'}>Price USD</div>
				<div className={'list_price_eur'}>Price EUR</div>
			</div>
			{priceList.length > 0 ? (
				priceList.map((prices) => (
					<WeightClassPrice
						key={prices.weight_class}
						price={prices}
						onUpdate={onUpdate}
						enableUpdate={enableUpdate}
						showPricesCol={showPricesCol}
					/>
				))
			) : (
				<p className={'price_no_data'}>No data available</p>
			)}

			{modal && (
				<HistoryModalComponent
					historyData={history}
					modal={modal}
					closeModal={closeModal}
					week={week}
					year={year}
				/>
			)}
		</div>
	);
};

export default PriceLine;
