import React, { useState, useEffect } from "react";
import "./CostTarget.css";
import WeekSelection from "../WeekSelection/WeekSelection";
import CurrencySelection from "../CurrencySelection/CurrencySelection";
import WeekSelector from "../GlobalMatrixCard/Modules/WeekSelector";
import axios from 'axios'
import CommonServices from "../../api/CommonServices";
import WeekCard from "../Cards/WeekCard";
import {GrClose, GrHistory} from 'react-icons/gr';
import { ToastsStore } from "react-toasts";
import Modal from 'react-modal';



const Header = (props) => {
  let { currencies, week, year, data, buttonDisable, updateCostTarget, getCostTarget, updateButtonDisable, previousPriceList,dates={sourcing:'',packing:''} } = props;

  const [incoterms, setIncoterms] = useState('');
  const [currency, setCurrency] = useState("NOK");
  const [sourcing_week, setSourcingWeek] = useState(props.sourcing_week);
  const [packing_week, setPackingWeek] = useState(props.packing_week);
  const [last_selector_week, setLastSelectorWeek] = useState('');
  const [first_allowed_week, setFirstAlowedWeek] = useState('');
  const [last_allowed_week, setLastAllowedWeek] = useState('');
  const [default_selected_week, setDefaultSelectedWeek] = useState('')

  const [isWeekFlexRow,setIsWeekFlexRow] = useState(true)

  const [historyModal,setHistoryModal] = useState(false)
  const [historyModalLoading,setHistoryModalLoading] = useState(false)
  const [historyModalData,setHistoryModalData] = useState({})

  const week_data_stats = [true, true, true];

  useEffect(() => {
    setIncoterms(data.incoterms ? data.incoterms : '')
    setCurrency(data.currency ? data.currency : 'NOK')
  }, [data])

  useEffect(() => {
    initiateNQSPage();
  }, [])


  const initiateNQSPage = async (params) => {
    const costTargetInitiate = await CommonServices.costTargetInitiate('purchaser');
    if (costTargetInitiate.data) {
      const { data } = costTargetInitiate;
      setSourcingWeek(data.result.default_selected_week)
      setFirstAlowedWeek(data.result.first_allowed_week)
      setLastSelectorWeek(data.result.last_selector_week)
      setLastAllowedWeek(data.result.last_allowed_week)
      setDefaultSelectedWeek(data.result.default_selected_week)
    }
  }

  useEffect(() => {
    getCostTarget(sourcing_week);
  }, [sourcing_week])

  const onSelectChange = (e) => {
    setCurrency(e);
    updateButtonDisable(false)
  };

  const updateIncoterms = (e) => {
    setIncoterms(e.target.value);
    updateButtonDisable(false)
  };

  const incrementWeek = (sourcingWeek, packingWeek) => {

    setSourcingWeek(sourcingWeek);
    setPackingWeek(packingWeek);

    getCostTarget(sourcingWeek, packingWeek)
  };

  const decrementWeek = (sourcingWeek, packingWeek) => {
    setSourcingWeek(sourcingWeek);
    setPackingWeek(packingWeek);

    getCostTarget(sourcingWeek, packingWeek);
  };


  const onWeekClick = (e) => {
    setSourcingWeek(e.target ? e.target.value : e)
  }
  const onBFsClick = () => {

  };
  const onToggleChange = () => {
    setLastSelectorWeek(last_allowed_week);
    setSourcingWeek(default_selected_week);
    setIsWeekFlexRow(!isWeekFlexRow)
  };

  const handleCostTargetHistory = async (limit) => {
    try {
      setHistoryModalLoading(true)
      const getHistory = await CommonServices.getCostTargetHistory(limit)
      if(getHistory.data){
        setHistoryModalData(getHistory.data)
      }
    } catch (err) {
      ToastsStore.error('An Error Occurred, Try Reloading.')
      setHistoryModalData({})
    } finally {
      setHistoryModalLoading(false)
      setHistoryModal(true)
    }
  }

  const customStyles = {
    content: {
      background: "white",
      maxWidth: "calc(100vw - 2rem)",
      maxHeight: "calc(100vh - 2rem)",
      boxshadow: "0 0 30px 0 rgba(0, 0, 0, 0.25)",
      overflowY: "auto",
      position: "relative",
    },
    overlay: {
      position: "fixed",
      zIndex: 999999,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  return (
    <>
      <Modal
                isOpen={historyModal}
                onRequestClose={() => {
                  setHistoryModal(false)
                  setHistoryModalData({})
                }}
                onAfterOpen={() => (document.body.style.overflow = "hidden")}
                onAfterClose={() => (document.body.style.overflow = "unset")}
                style={customStyles}
            >
                <div class="scrl_out" style={{ paddingBottom: "30px" }}>
                    <div className={"modal_heading"}>
                        <p>Cost Target History</p>
                        <div
                            className={"close-icon"}
                            onClick={() => {
                              setHistoryModal(false)
                              setHistoryModalData({})
                            }}
                        >
                            <GrClose size={25} />
                        </div>
                    </div>
                    <section className={"OrderTableWrap" + " " + "scrl_out historyTable"}>
                      {Object.keys(historyModalData).length>0 && <table>
                          <tr className={"table_head"}>
                              {historyModalData.heading.map((week, i) => {
                                  return <th>{week}</th>;
                              })}
                          </tr>

                          <tbody>
                              {historyModalData.matrix.map((key, index) => {
                                  return (
                                      <tr key={index}>
                                          <td>{key[0]}</td>
                                          {key[1].map((data, i) => {
                                              return <td>{data === 0 ? '-' : data}</td>;
                                          })}
                                      </tr>
                                  );
                              })}
                          </tbody>
                      </table>}
                    </section>
                </div>
            </Modal>
      <div class="scrl_out">
        <div className={"PriceWrap"}>
          <>
            <div className={"scrl_out"}>
              <div className={"ct_top"}>
                <section>
                    <div>
                      <div className={"cost_target_header_wrap"}>
                        {currencies && previousPriceList && (
                          <CurrencySelection
                            currenciesList={currencies}
                            onSelectChange={onSelectChange}
                            previousPriceList={previousPriceList}
                            selectedCurrency={currency}
                          />
                        )}
                      </div>
                      <div className={"cost_target_header"}>
                        <p>Incoterms: </p>

                        <input
                          type="text"
                          className={"input incoterms_input"}
                          value={incoterms}
                          onChange={(event) => updateIncoterms(event)}
                        />
                      </div>
                      <div className={"CT_lab_wrap"}>
                    <div className={'nasdaq_week ct_week_flex'}>
                      <div className={'nasdaq_week_wrapper'}>
                        {sourcing_week && (
                          <WeekSelector
                            selector_title={'Week'}
                            last_selector_week={last_selector_week}
                            first_allowed_week={first_allowed_week}
                            last_allowed_week={last_allowed_week}
                            selected_week={sourcing_week}
                            default_selected_week={default_selected_week}
                            week_limit={4}
                            week_data_stats={week_data_stats}
                            style={'bubbles'}
                            default_disabled_weeks={[]}
                            week_selector_toogle={true}
                            onWeekClick={onWeekClick}
                            onBFsClick={onBFsClick}
                            onToggleChange={onToggleChange}
                            is_disabled={false}
                            hideArrow={true}
                          />
                        )}
                      </div>
                      <div className="ct-history-wrapper" style={{flexDirection:isWeekFlexRow?'row':'column'}}>
                        <span onClick={(e)=>{
                          e.preventDefault()
                          handleCostTargetHistory(5)

                        }}>5W</span>
                        <span onClick={(e)=>{
                          e.preventDefault()
                          handleCostTargetHistory(10)

                        }}>10W</span>
                      </div>
                    </div>
                  </div>
                    </div>
                    <div className={`save_btn_cont`}>
                        <div
                          className={`${buttonDisable
                              ? "not_allow_cursor"
                              : "allow_cursor"
                            }`}
                        >
                          <div
                            className={"save_button"}
                            onClick={() => updateCostTarget(incoterms, currency, sourcing_week, packing_week)}
                          >
                            <p>Update</p>
                          </div>
                        </div>
                        <div  className="ct_weekbox_wrap">
                          <WeekCard 
                            title='Sourcing Week'
                            isCurrentWeek={default_selected_week===sourcing_week}
                            data={sourcing_week}
                            children={<p style={{fontSize:12}}>{dates.sourcing}</p>}
                          />
                          <WeekCard 
                            title='Shipment Week'
                            isCurrentWeek={false}
                            data={props.packing_week}
                            children={<p style={{fontSize:12}}>{dates.packing}</p>}
                          />
                        </div>
                      </div>
                </section>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Header;
