import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BaseOrder from '../BaseOrder/BaseOrder';
import './DashboardOverview.css';
import QuoteServices from '../../../api/QuoteServices';
import BrowserStorage from '../../../utils/BrowserStorage';

const DownStreamDashboard = () => {
	const [quoteDetails, setQuoteDetails] = useState(null);
	const [quotePresent, setQuotePresent] = useState(0);
	const [indicativeStatus, setIndicativeStatus] = useState('default');
	const [requestStatus, setRequestStatus] = useState('default');
	const [qtBtn, setQtBtn] = useState(null);
	const [indicBtn, setIndicBtn] = useState(null);
	const [rqBtn, setRqBtn] = useState(null);
	const [Qvo, setQvo] = useState(true);
	const [priceAvailable, setPriceAvailable] = useState(false);
	const [confirmOrderPlaced, setConfirmOrderPlaced] = useState(false);
	const [disableBuyBtn, setDisableBuyBtn] = useState(false);

	//FORCE UPDATE - STATE
	const [, updateState] = React.useState();

	useEffect(() => {
		getQuote();
	}, [quotePresent]);

	const forceUpdate = React.useCallback(() => updateState({}), []);

	const getQuote = async () => {
		const getQuote = await QuoteServices.getQuote();
		if (getQuote.data) {
			const { data } = getQuote;
			setPriceAvailable(data.has_prices);

			if (data.quote.id) {
				setQuotePresent(1);
			}
			setQuoteDetails(data.quote);
			processState(data);
		} else if (getQuote.error) {
			setIndicativeStatus('under_confirmation');
			setDisableBuyBtn(true)
			setRequestStatus('under_confirmation');
		}
	};

	const processState = (data) => {
		let indicative = 'default';
		let request = 'default';

		if (quotePresent !== 0 && data.has_prices) {
			indicative = 'view';
		}
		if (data.quote.status === 2 && !data.has_prices) {
			indicative = 'pending';
		}

		if (quotePresent !== 0 && data.has_volumes && data.quote.status === 1) {
			request = 'continue';
		}
		if (
			quotePresent !== 0 &&
			data.has_volumes &&
			!data.has_prices &&
			data.quote.status === 2
		) {
			console.log('quote_pending');
			request = 'quote_pending';
		}
		if (quotePresent !== 0 && data.has_volumes && data.has_prices) {
			request = 'view';
		}
		if (data.quotee_placed) {
			request = 'under_confirmation';
		}

		setIndicativeStatus(indicative);
		setRequestStatus(request);

		console.log(requestStatus);
	};

	const getPriceIndication = async () => {
		let data = {
			product: 1,
		};
		const getPriceIndication = await QuoteServices.getPriceIndication(data);
		if (getPriceIndication.data) {
			const { data } = getPriceIndication;
			if (data.data.id) {
				setIndicativeStatus('pending');
				forceUpdate();
			}
		}
	};

	const priceIndic = () => {
		if (indicativeStatus === 'pending') {
			return;
		}
		if (indicativeStatus === 'view') {
			window.location = '/home/price';
			return;
		}
		getPriceIndication();
	};

	const handleActBtn = (a) => {
		if (a !== 0) {
			setQtBtn(6);
			localStorage.setItem('requestQuoteStatus', 'request');
		}
		forceUpdate();
		console.log(qtBtn);
	};
	const updateOrderPlaced = (id) => {
		if (id !== 0) {
			setConfirmOrderPlaced(true);
		} else {

			setConfirmOrderPlaced(false);
		}
	};

	const userDetails = BrowserStorage.getValue('userInfo');

	return (
		<>
			<div className={'cont_wp'}>
				<section className={'cmn_head'}>
					<p>Downstream Dashboard</p>
					{/* <label>Create New Orders & Modify base orders here</label> */}
				</section>
				<div className={'scrl_out'}>
					<section
						className={'home_top_wrap'}
						style={{ gridTemplateColumns: '1fr 1.5fr' }}
					>
						<div className={'home_top_block'}>
							<div className={'home_blk_stat_wrap'}>
								<div className={'home_blk_stat'}>
									<h3>Manage Your Seafood</h3>
									<label>Manage your supply chain here</label>

									<section className={'home_bt_out_wp'}>
										{
											<>
												{disableBuyBtn ? (
													<div className={'disabled_btn disable'}>Buy</div>
												) : (
													<Link to='/home/request'>
														<div className={'disabled_btn'}>Buy</div>
													</Link>
												)}
												<div className={'disabled_btn-container'}>
													<div className={'disabled_btn not-allowed'}>
														<div className='flight_ico'></div>
													</div>
												</div>
												<div className={'disabled_btn-container'}>
													<div className={'disabled_btn not-allowed'}>
														<div>Sell</div>
													</div>
												</div>
												{confirmOrderPlaced?<div className='button_disabled_price'>
                          Order Placed
                        </div>:indicativeStatus !== 'under_confirmation' && (
													<div
														onClick={() => priceIndic()}
														className={
															indicativeStatus === 'continue'
																? 'button_circle'
																: indicativeStatus === 'pending'
																? 'button_disabled_price'
																: 'button_circle sp_btn'
														}
													>
														{indicativeStatus === 'pending'
															? 'Price Pending'
															: indicativeStatus === 'view'
															? 'View Price'
															: 'Ask Price'}
													</div>
												)}
											</>
										}
									</section>
									<section></section>
								</div>
							</div>
						</div>
						<div className='home_top_block d-flex'>
							<div className={'home_blk_stat_wrap'}>
								<div className={'home_blk_stat'}>
									<div className={'title'}>
										{/* <div className={'count_tag'}>Quote Id()</div> */}
										{<h3>Active Discussions</h3>}
										{quoteDetails?.id && <label>Quote : {quoteDetails?.id}</label>}
										<p></p>
										{quoteDetails?.id && (
											<label>Shipment Week : {quoteDetails?.shipment_week}</label>
										)}
										{quotePresent === 0 ? (
											<label>No Active Discussions</label>
										) : (
											<label></label>
										)}

										<div className={'home_conf_outer'}>
											{indicativeStatus === 'view' && (
												<div className={'home_conf_status'}>
													<span>Price Available</span>
												</div>
											)}
											{indicativeStatus === 'pending' && (
												<div className={'home_conf_status'}>
													<span>Price Pending From Admin</span>
												</div>
											)}
											{requestStatus === 'continue' && (
												<div className={'home_conf_status' + ' ' + 'req_status'}>
													{qtBtn === 6 ? (
														<span>Building Quote</span>
													) : (
														<span>Building Request</span>
													)}
												</div>
											)}
											{requestStatus === 'under_confirmation' &&
												userDetails.access_type !== 'prospect' && (
													<div className={'home_conf_status' + ' ' + 'req_status'}>
														<span>Pending Order Confirmation</span>
													</div>
												)}

											{indicativeStatus === 'quote_pending' && (
												<div className={'home_conf_status' + ' ' + 'req_status'}>
													{qtBtn === 6 && <span>Quote Pending From Admin</span>}
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className={'home_top_wrap1'}>
						<BaseOrder
							actBtn={handleActBtn}
							updateOrderPlaced={updateOrderPlaced}
							setDisableBuyBtn={setDisableBuyBtn}
						/>
					</section>
				</div>
			</div>
		</>
	);
};
export default DownStreamDashboard;
