import React, { Component } from 'react';
import axios from 'axios';
import { ToastsStore } from 'react-toasts';
import { Link } from 'react-router-dom';

class GetOtp extends Component {
	constructor() {
		super();
		this.state = {
			mailId: '',
			proceedContent: '',
			getOtpForm: true,
		};
	}
	inputChangedHandler = (event) => {
		this.setState({
			mailId: event.target.value,
		});
	};
	submitHandler = (event) => {
		let reg =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (reg.test(this.state.mailId) === false) {
			ToastsStore.error('Email is not correct');
		} else {
			const authData = {
				email: this.state.mailId,
				is_admin: true,
			};
			axios
				.post('/customer/api/forgot-password/', authData)
				.then((response) => {
					this.setState({
						getOtpForm: false,
						proceedContent: response.data.data,
					});
				})
				.catch((error) => {
					console.log('aaa', error);
				});
		}
	};
	proceed = (event) => {
		this.props.submitOtp(false);
	};

	render() {
		return (
			<div className={'loginFormWrap'}>
				<p className={'loginFormHead'}>Get OTP</p>
				{this.state.getOtpForm ? (
					<p className={'loginFormSubHead'}>
						Enter your registered E-mail id to get OTP
					</p>
				) : null}
				{this.state.getOtpForm ? (
					<>
						<div className={'Input'}>
							<label>E-mail</label>
							<input
								type='text'
								onChange={(event) => this.inputChangedHandler(event)}
								placeholder='Mail Address'
							/>
						</div>
						<div className={'otp_btn'} onClick={(event) => this.submitHandler()}>
							Get OTP
						</div>
					</>
				) : (
					<>
						<div className={'otp_btn'} onClick={(event) => this.proceed()}>
							Proceed
						</div>
					</>
				)}
			</div>
		);
	}
}

export default GetOtp;
