import React, { useEffect, useState } from 'react';
import { GrAddCircle } from 'react-icons/gr';
import { IoAddCircleOutline } from 'react-icons/io5';
import Moment from 'react-moment';

import WeightClassWeekdayVolume from './WeightClassWeekdayVolume';

const OrderVolumeMatrix = ({
	orderVolumeMatrix,
	onUpdate,
	enableUpdate,
	createVolume,
	buttonDisable = true,
	forecastOrderId,
	eighthDay = false,
	// setButtonDisable,
	hideButton,
	enable_subtractor = false,
	state = {
		matrix_dimension_status: 3,
		table_header_add_visibility: false,
		filter_day_index: [],
	},
	daysOrder,
	type,
	viewType,
	toggle = true,
	handleAddPacking = () => {},
	// noBackground
}) => {
	const [volumeMatrix, setVolumeMatrix] = useState(orderVolumeMatrix);
	const [weightClasses, setWeightClasses] = useState([]);

	useEffect(() => {
		setVolumeMatrix(orderVolumeMatrix);
		setWeightClasses(Object.keys(orderVolumeMatrix));
	}, [eighthDay, orderVolumeMatrix]);

	// useEffect(() => {
	// 	setWeightClasses(Object.keys(orderVolumeMatrix));
	// }, []);

	return (
		<>
			<table style={{ width: '100%', borderSpacing: 0 }}>
				<thead>
					{state && // 👈 null and undefined check
						Object.keys(state).length !== 0 &&
						state.constructor === Object && (
							<tr className='table-header'>
								{state.matrix_dimension_status !== 1 ? (
									<>
										<th style={{ width: 100 }}></th>
										{state.week_data.map((val, i) => {
											return (
												<th
													style={{
														textAlign: 'center',
													}}
													key={i}
												>
													<span
														className={'inData table-content'}
														style={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
														}}
													>
														{/* {daysOrder[i + 1]}{' '}
													{state.matrix_model !== 'BaseOrder' && (
														<Moment format='MMM DD'>{val}</Moment>
													)} */}
														{state.matrix_model !== 'BaseOrder' ? val : daysOrder[i + 1]}
														&nbsp;
														{state.table_header_add_visibility &&
															state.filter_day_index.includes(i + 1) && (
																<IoAddCircleOutline
																	color='#fff'
																	size={18}
																	style={{ cursor: 'pointer' }}
																	onClick={(e) => {
																		e.preventDefault();
																		handleAddPacking(val);
																	}}
																/>
															)}
													</span>
												</th>
											);
										})}
										{state.matrix_dimension_status === 3 && (
											<th style={{ textAlign: 'center' }}>
												<span className='inData table-content'>
													{state.shipment_week ? state.shipment_week : 'Undistributed'}
												</span>
											</th>
										)}{' '}
									</>
								) : (
									<>
										<th></th>
										<th style={{ textAlign: 'center' }}>
											<span
												className={
													state.matrix_model === 'BaseOrder'
														? 'inData table-content'
														: 'inData'
												}
											>
												{state.shipment_week}
												<br />
												{state.matrix_model !== 'BaseOrder' && (
													<>{state.shipment_date_9_1}</>
												)}
											</span>
										</th>
									</>
								)}
							</tr>
						)}
				</thead>
				<tbody>
					{toggle &&
						weightClasses.map((weight_class, index) => (
							<tr key={index}>
								<th style={{ width: 100 }}>HOG {weight_class} kg</th>
								{volumeMatrix[weight_class].map((item, i) => (
									<td key={i}>
										{type === 'view' || viewType === 'forecast_order_view' ? (
											<WeightClassWeekdayVolume
												key={weight_class}
												volumeObj={item}
												weightClass={weight_class}
												onUpdate={onUpdate}
												enableUpdate={false}
												// setButtonDisable={setButtonDisable}
											/>
										) : (
											<WeightClassWeekdayVolume
												key={weight_class}
												volumeObj={item}
												weightClass={weight_class}
												onUpdate={onUpdate}
												enableUpdate={
													i === volumeMatrix[weight_class].length - 1
														? !enable_subtractor
														: enableUpdate
												}
												// setButtonDisable={setButtonDisable}
											/>
										)}
									</td>
								))}
							</tr>
						))}
				</tbody>
			</table>
		</>
	);
};

export default OrderVolumeMatrix;
